<template>
  <v-dialog
    v-model="isVisibleDeleteDialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("delete_confirmation_message") }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="toggleDelete()"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="deleteItemConfirm(oem)"
        >
          {{ $t("ok") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isVisibleDeleteDialog: Boolean,
    toggleDelete: {
      type: Function,
      default() {
        return void 0;
      },
    },
    oem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    async deleteItemConfirm(oem) {
      try {
        await this.$store.dispatch("oemclients/destroy", oem);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDelete();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
